<template lang="pug">
.overlay.overlay-login
  AppLink(:to="referrer" :title="$t('close')" class="overlay-close") #[AppIcon(name='close')]
  .overlay-body.u-mh-md
    .container
      LoginForm
</template>

<script>
import { generateSeoMeta } from '~/utils/seo';
import { referrerMixin } from '~/mixins/referrer';
import LoginForm from '~/components/forms/LoginForm';

export default {
  mixins: [referrerMixin],
  middleware: 'guest',
  transition: 'overlay',
  head() {
    const seo = generateSeoMeta({ title: 'pageTitle', description: 'pageDesc' }, { i18n: true }).call(this);

    return {
      ...seo,
      bodyAttrs: {
        class: 'is-scroll-disabled'
      }
    };
  },
  components: {
    LoginForm
  }
};
</script>

<i18n>
{
  "en": {
    "pageTitle": "Login",
    "pageDesc": "Login to Baianat and have an account for all your services and enjoy the login option on Baianat ventures websites."
  },
  "ar": {
    "pageTitle": "تسجيل الدخول",
    "pageDesc": "سجل الآن على بيانات واحصل على حساب من أجل متابعة الخدمات الخاصة بك وتمتع بالعديد من المميزت أهمها استكشاف مشاريع بيانات."
  }
}
</i18n>
