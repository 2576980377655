export function isEmpty(val) {
  return val === null || val === undefined || val === '';
}

export function getPath(path, target, def) {
  if (!path || !target) return def;

  let value = target;
  path.split('.').every(prop => {
    if (value && prop in value && !isEmpty(value[prop])) {
      value = value[prop];

      return true;
    }

    value = def;

    return false;
  });

  return value;
}

export function generateContentSeo(content) {
  return {
    title: content.title,
    meta: [
      { hid: 'description', name: 'description', content: content.description },
      {
        hid: 'og:title',
        name: 'og:title',
        property: 'og:title',
        content: getPath('seo.facebook.title', content, content.title)
      },
      {
        hid: 'og:description',
        name: 'og:description',
        property: 'og:description',
        content: getPath('seo.facebook.description', content, content.description)
      },
      {
        hid: 'twitter:card',
        name: 'twitter:card',
        property: 'twitter:card',
        content: getPath('seo.twitter.card', content, 'summary_large_image').toLowerCase()
      },
      {
        hid: 'twitter:site',
        name: 'twitter:site',
        property: 'twitter:site',
        content: getPath('seo.twitter.site', content, '@baianat')
      },
      {
        hid: 'og:type',
        name: 'og:type',
        property: 'og:type',
        content: content.type
      },
      {
        hid: 'og:image',
        name: 'og:image',
        property: 'og:image',
        content: getPath('seo.facebook.thumb', content, content.thumb)
      },
      // { hid: 'twitter:creator', name: 'twitter:creator', property: 'twitter:creator', content: this.article.s },
      {
        hid: 'twitter:image',
        name: 'twitter:image',
        property: 'twitter:image',
        content: getPath('seo.twitter.thumb', content, content.thumb)
      },
      {
        hid: 'twitter:title',
        name: 'twitter:title',
        property: 'twitter:title',
        content: getPath('seo.twitter.title', content, content.title)
      },
      {
        hid: 'twitter:description',
        name: 'twitter:description',
        property: 'twitter:description',
        content: getPath('seo.twitter.description', content, content.description)
      }
    ]
  };
}

export function generateSeoMeta({ title, description, type, image }, { i18n = false } = {}) {
  return function() {
    const normalize = str => {
      if (!i18n) return str;

      const replacer = str => {
        return str.replace(/\{CDN_URL}/g, this.$store.state.env.CDN_URL);
      };

      // handle pure image sources
      if (image && /\.(png|jpg|webp)$/.test(image)) {
        return replacer(image);
      }

      return this.$t(str);
    };

    return {
      title: normalize(title),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: normalize(description)
        },
        {
          hid: 'og:title',
          name: 'og:title',
          property: 'og:title',
          content: normalize(title)
        },
        {
          hid: 'og:description',
          name: 'og:description',
          property: 'og:description',
          content: normalize(description)
        },
        {
          hid: 'twitter:card',
          name: 'twitter:card',
          property: 'twitter:card',
          content: 'summary_large_image'
        },
        {
          hid: 'twitter:site',
          name: 'twitter:site',
          property: 'twitter:site',
          content: '@baianat'
        },
        {
          hid: 'og:type',
          name: 'og:type',
          property: 'og:type',
          content: type || 'website'
        },
        {
          hid: 'og:image',
          name: 'og:image',
          property: 'og:image',
          content: normalize(image || 'https://cdn.baianat.com/static/share.png')
        },
        // { hid: 'twitter:creator', name: 'twitter:creator', property: 'twitter:creator', content: this.article.s },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          property: 'twitter:image',
          content: normalize(image || 'https://cdn.baianat.com/static/share.png')
        },
        {
          hid: 'twitter:title',
          name: 'twitter:title',
          property: 'twitter:title',
          content: normalize(title)
        },
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          property: 'twitter:description',
          content: normalize(description)
        }
      ].filter(Boolean)
    };
  };
}

export function generateArticleSchema(article, type = 'Article') {
  const date = new Date();
  date.setTime(article.publishDate || article.creationDate);
  const org = {
    '@type': 'Organization',
    name: 'Baianat',
    logo: {
      '@type': 'ImageObject',
      url: 'https://www.baianat.com/icon.png'
    }
  };

  return {
    '@context': 'https://schema.org',
    '@type': type,
    headline: article.title,
    description: article.description,
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': 'https://google.com/article'
    },
    image: [article.thumb],
    datePublished: date.toISOString(),
    dateModified: date.toISOString(),
    author: org,
    publisher: org
  };
}

export function generateBookSchema(book) {
  const org = {
    '@type': 'Organization',
    name: 'Baianat',
    logo: {
      '@type': 'ImageObject',
      url: 'https://www.baianat.com/icon.png'
    }
  };

  return {
    '@context': 'https://schema.org',
    '@type': 'Book',
    name: book.title,
    author: org,
    url: `https://www.baianat.com/books/${book.slug}`
  };
}

export function generateCareerSchema(career) {
  const date = new Date();
  date.setTime(career.creationDate);

  return JSON.stringify({
    '@context': 'https://schema.org/',
    '@type': 'JobPosting',
    title: career.title,
    description: `<p>${career.description}</p>`,
    identifier: {
      '@type': 'PropertyValue',
      name: 'Baianat',
      value: career.id
    },
    jobLocation: {
      '@type': 'Place',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'Near Culture Palace, Central Zone New Damietta City Road، Damietta Governorate',
        addressLocality: 'New Damietta',
        addressRegion: 'Damietta',
        postalCode: '34518',
        addressCountry: 'EG',
        telephone: '+201000111888'
      }
    },
    datePosted: date.toISOString(),
    employmentType: career.type,
    hiringOrganization: {
      '@type': 'Organization',
      name: 'Baianat',
      sameAs: ['https://www.facebook.com/baianat', 'https://twitter.com/baianat', 'https://github.com/baianat'],
      logo: 'http://www.baianat.com/icon.png'
    },
    applicantLocationRequirements: {
      '@type': 'Country',
      name: 'Egypt'
    }
  });
}
