export const referrerMixin = {
  data: () => ({
    referrer: '/'
  }),
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (/\/(menu|login|search|register)/.test(from.fullPath)) {
        return;
      }

      let isAr = /^\/ar\/?/.test(from.fullPath);
      let referrer = from.fullPath.replace(/^\/ar\/?/, '/');
      if (/\/$/.test(referrer) && isAr) {
        referrer = referrer.replace(/\/$/, '');
      }

      vm.referrer = referrer;
    });
  }
};
